<div dougsModalTitle>
  <h6>Sélectionner une raison d’exonération de TVA</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div class="force-max-height no-padding" dougsModalContent>
  <ng-container
    *ngIf="selectVatExemptionReasonModalComponentService.refreshVatExemptionReasons$ | async"
  ></ng-container>
  <ng-container *ngIf="!(selectVatExemptionReasonModalComponentService.isLoading$ | async); else loader">
    <div class="mt-16 px-32" *ngIf="(selectVatExemptionReasonModalComponentService.reasons$ | async)?.length">
      <div
        (click)="selectVatExemptionReasonModalComponentService.selectReason(reason)"
        class="reason-line pointer py-8"
        *ngFor="let reason of selectVatExemptionReasonModalComponentService.reasons$ | async; trackBy: 'flag' | trackBy"
      >
        <div class="reason-line__flag">
          <img *ngIf="reason.flag" class="mr-8" src="images/flags/{{ reason.flag }}.svg" />
        </div>
        <div class="reason-line__label">
          <p class="small color-primary-700">
            {{ reason.label }}
          </p>
          <p class="tiny color-gray-350" [innerHTML]="reason.subLabel"></p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loader>
    <dougs-loader></dougs-loader>
  </ng-template>
</div>
