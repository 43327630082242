import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { SelectVatExemptionReasonModalComponentService } from '../../services/select-vat-exemption-reason-modal.component.service';

@Component({
  selector: 'dougs-select-vat-exemption-reason-modal',
  templateUrl: './select-vat-exemption-reason-modal.component.html',
  styleUrls: ['./select-vat-exemption-reason-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    TrackByPipe,
    LoaderComponent,
  ],
  providers: [SelectVatExemptionReasonModalComponentService],
})
export class SelectVatExemptionReasonModalComponent implements OnInit {
  constructor(
    public readonly selectVatExemptionReasonModalComponentService: SelectVatExemptionReasonModalComponentService,
  ) {}

  ngOnInit(): void {
    this.selectVatExemptionReasonModalComponentService.refreshVatExemptionReasons();
  }
}
