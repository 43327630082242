import { ElementRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, delay, Observable, ReplaySubject, tap } from 'rxjs';
import { HelpModalComponent, MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { EcommerceVatExemptionReason } from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService } from '@dougs/ecommerce/shared';
import { Operation } from '@dougs/operations/dto';

@Injectable()
export class SelectVatExemptionReasonModalComponentService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private readonly reasons: ReplaySubject<EcommerceVatExemptionReason[]> = new ReplaySubject<
    EcommerceVatExemptionReason[]
  >(1);
  reasons$: Observable<EcommerceVatExemptionReason[]> = this.reasons.asObservable();

  private readonly refreshVatExemptionReasonsSubject: ReplaySubject<void> = new ReplaySubject<void>(1);
  refreshVatExemptionReasons$: Observable<EcommerceVatExemptionReason[]> = this.refreshVatExemptionReasonsSubject
    .asObservable()
    .pipe(
      tap(() => this.isLoading.next(true)),
      concatMap(() => this.ecommerceSaleStateService.getVatExemptionReasons(this.data.operation)),
      tap((reasons: EcommerceVatExemptionReason[]) => this.reasons.next(reasons)),
      tap(() => this.isLoading.next(false)),
      delay(0),
      tap(() => this.addClickEventListenerOnInjectedHTML()),
    );

  constructor(
    @Inject(MODAL_DATA)
    public data: { sectionId: string; subSectionId: string; operation: Operation; canHavePackageType: boolean },
    private readonly ecommerceSaleStateService: EcommerceSaleStateService,
    private readonly modalService: ModalService,
    private readonly modalRef: ModalRef,
    private readonly elementRef: ElementRef,
  ) {}

  europeanStateModalButton?: HTMLElement;

  refreshVatExemptionReasons(): void {
    this.refreshVatExemptionReasonsSubject.next();
  }

  selectReason(reason: EcommerceVatExemptionReason): void {
    this.modalRef.close({
      sectionId: this.data.sectionId,
      subSectionId: this.data.subSectionId,
      saleId: reason.saleId,
    });
  }

  private addClickEventListenerOnInjectedHTML(): void {
    this.europeanStateModalButton = this.elementRef.nativeElement.querySelector('.european-state-modal-button');
    if (this.europeanStateModalButton) {
      this.europeanStateModalButton.addEventListener('click', (e) => this.openEuropeanUnionStatesModal(e));
    }
  }

  private openEuropeanUnionStatesModal(e: Event): void {
    e.stopPropagation();
    this.modalService.open(HelpModalComponent, {
      data: {
        title: "Pays membres de l'Union européenne",
        body:
          "Les pays membres de l'Union européenne sont l'Allemagne, l'Autriche, la Belgique, la Bulgarie, Chypre, " +
          "la Croatie, le Danemark, l'Espagne, l'Estonie, la Finlande, " +
          "la France, la Grèce, la Hongrie, l'Irlande, l'Italie, la Lettonie, " +
          'la Lituanie, le Luxembourg, Malte, les Pays-Bas, la Pologne, ' +
          'le Portugal, la République tchèque, la Roumanie, ' +
          'la Slovaquie, la Slovénie et la Suède.',
      },
    });
  }
}
